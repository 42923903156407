export const environment = {
  production: true,
  //baseUrl: 'http://192.168.0.100:5000/api/',


/*  baseUrl: 'https://KuwaitMembersApiDev.softlinktest.com/api/',
  faviconUrl: 'https://KuwaitMembersApiDev.softlinktest.com/Resources/favicon.ico',
  logoUrl: 'https://KuwaitMembersApiDev.softlinktest.com/Resources/kuwaitclub_logo.png',
  home_title_color: 'nero-color',
  card_text_color: 'text-white',
  login_title: 'نادى الكويت الرياضى',
  card_bg_color: 'main-bg-color',
  PROJECT_NAME: 'Kuwait Members',*/


  /*baseUrl: 'https://JahraMembersApiDev.softlinktest.com/api/',
  faviconUrl: 'https://JahraMembersApiDev.softlinktest.com/Resources/favicon.ico',
  logoUrl: 'https://JahraMembersApiDev.softlinktest.com/Resources/jahra_logo.png',
  home_title_color: 'nero-color',
  card_text_color: 'text-white',
  login_title: 'نادى الجهراء الرياضى',
  card_bg_color: 'main-bg-color',
  PROJECT_NAME: 'Jahra Members',
*/

  /*baseUrl: 'https://AlsalmiyaMembersApiDev.softlinktest.com/api/',
  faviconUrl: 'https://AlsalmiyaMembersApiDev.softlinktest.com/Resources/favicon.ico',
  logoUrl: 'https://AlsalmiyaMembersApiDev.softlinktest.com/Resources/alsalmiyaclub_logo.png',
  home_title_color: 'nero-color',
  card_text_color: 'text-white',
  login_title: 'نادى السالمية الرياضى',
  card_bg_color: 'main-bg-color',
  PROJECT_NAME: 'Alsalmiya Members',*/


 /* baseUrl: 'https://AlyarmoukMembersApiDev.softlinktest.com/api/',
  faviconUrl: 'https://AlyarmoukMembersApiDev.softlinktest.com/Resources/favicon.ico',
  logoUrl: 'https://AlyarmoukMembersApiDev.softlinktest.com/Resources/logo_Alyarmouk.jpg',
  home_title_color: 'nero-color',
  card_text_color: 'text-white',
  login_title: 'نادى اليرموك الرياضى',
  card_bg_color: 'main-bg-color',
  PROJECT_NAME: 'Alyarmouk Members',*/

/*  baseUrl: 'https://QadsiaMembersApiDev.softlinktest.com/api/',
  faviconUrl: 'https://QadsiaMembersApiDev.softlinktest.com/Resources/favicon.ico',
  logoUrl: 'https://QadsiaMembersApiDev.softlinktest.com/Resources/logo_qadsia.png',
  home_title_color: 'nero-color',
  card_text_color: 'text-white',
  login_title: 'نادى القادسية الرياضى',
  card_bg_color: 'main-bg-color',
  PROJECT_NAME: 'Qadsia Members',*/

  /* baseUrl: 'https://QClubMembersApiDev.softlinktest.com/api/',
   faviconUrl: 'https://QClubMembersApiDev.softlinktest.com/Resources/favicon.ico',
   logoUrl: 'https://QClubMembersApiDev.softlinktest.com/Resources/Qurainsclogo.png',
   home_title_color: 'nero-color',
   card_text_color: 'text-white',
   login_title: 'نادى القرين الرياضى',
   card_bg_color: 'main-bg-color',
   PROJECT_NAME: 'QClub Members',*/

  baseUrl: 'https://kazmaapiv2.softlinkkw.com/api/',
  faviconUrl: 'https://kazmaapiv2.softlinkkw.com/Resources/favicon.ico',
  logoUrl: 'https://kazmaapiv2.softlinkkw.com/Resources/Kazmasc-nav.svg',
  home_title_color: 'main-color',
  card_text_color: 'text-white',
  login_title: 'نادى كاظمة الرياضى',
  card_bg_color: 'main-bg-color',
   PROJECT_NAME: 'Kazma Members',

  DEFAULT_LANGUAGE: 'ar',
  DEFAULT_TIMEOUT: 10000,
  TOAST_DURATION: 3000,
  FESS_AMOUNT: 10,
};
